import { BrowserRouter } from "react-router-dom";
import AppRoutes from './AppRoutes';
import './sass/main.scss';
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";
import { useHotjar } from "./hooks/useHotjar";

const App: React.FC = () => {
  useGoogleAnalytics();
  useHotjar();

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
