import { Stack } from "@mui/material";
import React, { useContext } from "react";
import Panel from "../../atoms/layout/Panel";
import Badge from "../../atoms/shapes/Badge";
import { PassTitle, RegularText } from "../../atoms/Typography";
import { PassPlan } from "../../services/PassS";
import PassAvatar from "./PassAvatar";
import { RelativeBox, AbsoluteBox } from "../../atoms/layout/Boxes";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import IconWrapper from "../../atoms/IconWrapper";
import { WindowContext } from "../../contexts/WindowContext";
import { NavigateFunction, useNavigate } from "react-router-dom";

const Plan: React.FC<{
  pass: PassPlan;
}> = ({ pass }) => {
  const navigate: NavigateFunction = useNavigate();
  const { isDesktopDevice, isPhoneDevice } = useContext(WindowContext);

  return (
    <div
      style={{ paddingBottom: 30, cursor: 'pointer' }}
      onClick={() => navigate(`/shopping-cart?pass=${pass.label}`)}
    >
      <Panel>
        <RelativeBox
          style={{ width: "12rem", height: "12rem", margin: "auto" }}
        >
          <PassAvatar pass={pass} size={"big"} />
          <AbsoluteBox style={{ bottom: -5, right: 0 }}>
            <Badge price={pass.price} />
          </AbsoluteBox>
        </RelativeBox>
        <Stack justifyContent={"center"} spacing={1}>
          <Panel>
            <PassTitle>{pass.longName.toUpperCase()}</PassTitle>
            <PassTitle>{pass.desc} PASS</PassTitle>
          </Panel>
          <Panel>
            <RegularText>
              <b>{pass.days} Tage</b> Gültigkeit
            </RegularText>
            <RegularText>Keine automatische Verlängerung</RegularText>
          </Panel>
          {isDesktopDevice && (
            <Panel>
              <br />
              <IconWrapper
                big={!isPhoneDevice}
                bgColor={"white"}
                withoutOpacity
              >
                <ShoppingCartIcon
                  sx={{ fontSize: isPhoneDevice ? 25 : 30 }}
                  onClick={() => navigate(`/shopping-cart?pass=${pass.label}`)}
                />
              </IconWrapper>
            </Panel>
          )}
        </Stack>
      </Panel>
    </div>
  );
};

export default Plan;
