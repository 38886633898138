import { HttpS } from "./HttpS";
import { AlertS, AlertType } from "./AlertS";
import { LocalStorageS } from "./LocalStorageS";
import { JwtUtils } from "../utils/JwtUtils";

export type ApplicationUser = {
  email?: string,
  username?: string, 
  password?: string
}

export type Contact = {
  id: number;
  email: string,
  firstName: string,
  lastName: string,
  addressStreet: string,
  streetNumber: string,
  zipCode: string,
  city: string,
  birthday: string,
  startDate?: Date
  filled: boolean;
}

export type ResetPasswordDTO = {
  newPassword: string
  oldPassword?: string
}

const registerApplicationUser = (userDTO: ApplicationUser): Promise<AlertType> => {
  return HttpS.postNonAuth(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/user/customer/sign-up`,
    userDTO
  ).then((resp: Response) => {
    if (resp.ok) {
      return AlertS.Alert.SUCCESS;
    } else if (resp.status === 409) {
      return AlertS.Alert.USER_ALREADY_EXISTS;
    } else {
      return AlertS.Alert.SERVER_UNAVAILABLE;
    }
  }).catch((err: Error) => {
    console.log(err);
    return AlertS.Alert.SERVER_UNAVAILABLE;
  })
} 

const confirmRegistration = (token : string): Promise<boolean> => {
  return HttpS.get(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/user/confirm/${token}`
  ).then((resp: Response) => resp.ok);
} 

const login = (userDTO: ApplicationUser): Promise<AlertType> => {
  return HttpS.post(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/login`,
    userDTO
  ).then((resp: Response) => {
    if (resp.status === 200) {
      const jwt = resp.headers.get('Authorization');
      if (jwt) {
        const claims: any = JwtUtils.decodeToken(jwt);
        if (claims.role === 'CUSTOMER') {
          LocalStorageS.putJWT(jwt);
          return AlertS.Alert.SUCCESS;
        } else {
          return AlertS.Alert.INVALID_CREDS;
        }
      }
    }
    else if (resp.status === 403) {
      return AlertS.Alert.INVALID_CREDS;
    } else {
      return AlertS.Alert.SERVER_UNAVAILABLE;
    }

  }).catch((err: any) => {
    console.log(err);
    return AlertS.Alert.SERVER_UNAVAILABLE;
  })
}


const resendVerificationMail = (recipient: string): Promise<boolean> =>  {
  return HttpS.post(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/user/verification/resend`,
    { recipient }
  ).then((resp: Response) => resp.ok);
}

const verifyToken = (token: string): Promise<boolean> => {
  return HttpS.get(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/user/verify-token?token=${token}`
  ).then((resp: Response) => resp.ok);
}

const requestResetPassword = (email: string): Promise<boolean> => {
  return HttpS.post(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/user/password-forgotten`,
    { email }
  ).then((resp: Response) => resp.ok);
} 

const resetPassword = (newPassword: string, token: string): Promise<boolean> => {
  return HttpS.post(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/user/reset-password?token=${token}`, 
    { newPassword }
  ).then((resp: Response) => resp.ok);
} 

const refreshToken = (): Promise<Response> => {
  return HttpS.get(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/applicationUsers/refresh-token`
  );
}

const getContactInformation = (): Promise<Contact | undefined> => {
  return HttpS.get(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/contacts/profile`
  ).then((resp: Response) => {
    if (resp.ok) {
      return resp.json();
    }
  }).catch((err: any) => {
    console.log(err);
    console.error("could not load own contact of profile");
  });
}

const updateContactInformation = (contact: Contact): Promise<boolean> => {
  return HttpS.patch(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/contacts/profile`,
    contact
  ).then((resp: Response) => resp.ok);
}


const getProfileContactInformation = (profileId: string): Promise<Contact | undefined> => {
  return HttpS.get(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/contacts/profile/${profileId}`
  ).then(resp => {
    if (resp.ok) {
      return resp.json().then((contact : Contact) => contact);
    }
  });
}

const changePassword = (dto: ResetPasswordDTO): Promise<boolean> => {
  return HttpS.patch(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/user/change-password`,
    dto
  ).then((resp: Response) => resp.ok);
} 

export const AuthS = {
  registerApplicationUser,
  confirmRegistration,
  login,
  resendVerificationMail,
  verifyToken,
  requestResetPassword,
  resetPassword,
  refreshToken,
  getContactInformation,
  updateContactInformation,
  getProfileContactInformation,
  changePassword
}

