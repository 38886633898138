import { useEffect } from "react";
import { hotjar } from "react-hotjar";

export const useHotjar = () => {
  const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
  const HOTJAR_SV = process.env.REACT_APP_HOTJAR_VERSION;

  useEffect(() => {
    if (HOTJAR_ID && HOTJAR_SV) {
      hotjar.initialize({
        id: parseInt(HOTJAR_ID),
        sv: parseInt(HOTJAR_SV),
      });
    } else {
      console.warn(
        "Skipping hotjar initialization for the current environment."
      );
    }
  }, [HOTJAR_ID, HOTJAR_SV]);
};
