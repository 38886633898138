import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, NavigateFunction } from "react-router-dom";
import { RoutingS } from "../services/RoutingS";
import { LocalStorageS } from "../services/LocalStorageS";
import { AuthS } from "../services/AuthS";
import { JwtUtils } from '../utils/JwtUtils';

export const AppUserContext = React.createContext<any>({});

export const AppUserContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const { pathname } = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const [isTokenValid, setTokenValidity] = useState<boolean>(JwtUtils.decodeToken() !== undefined);
  const [tick, setTick] = useState<any>();

  useEffect(() => {
    setTick(
      setInterval(validateToken, 1000)
    );
    return () => clearInterval(tick);
  }, []);

  useEffect(() => {
    if (!isTokenValid) {
      LocalStorageS.deleteJWT();
      if (RoutingS.isRouteProtected(pathname)) {
        navigate('/login');
      }
    }
  }, [isTokenValid])

  useEffect(() => {
    const auth = JwtUtils.decodeToken();
    if (auth) {
      AuthS.refreshToken().then(resp => {
        if (resp.ok) {
          resp.text().then(
            LocalStorageS.putJWT
          );
        }
      })
      if (RoutingS.isNonAuthRoute(pathname)) {
        navigate('/');
      }
    } else {
      if (RoutingS.isRouteProtected(pathname)) {
        navigate('/register');
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  const validateToken = () => {
    const auth = JwtUtils.decodeToken();
    if (auth) {
      setTokenValidity(true);
    } else {
      setTokenValidity(false);
    }
  }

  return (
    <AppUserContext.Provider value={{
      isAuthenticated: isTokenValid,
    }}>
      {children}
    </AppUserContext.Provider>
  );
}
